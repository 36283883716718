.PresetDateRangePicker_panel {
    padding: 0 22px 11px
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer
  }
  .PresetDateRangePicker_button:active {
    outline: 0
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
  }
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
  }
  .CalendarDay:active {
    outline: 0
  }
  .CalendarDay__defaultCursor {
    cursor: default
  }
  .CalendarDay__default {
    color: var(--mdc-theme-onSecondary);
    background: var(--mdc-theme-main);
  }
  .CalendarDay__default:hover {
    background: var(--mdc-theme-secondary);
    color: #fff;
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    color: inherit
  }
  .CalendarDay__outside {
    border: 0;
    color: #f1f2f3
  }
  .CalendarDay__outside:hover {
    border: 0
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    color: #cacccd
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
  }
  .CalendarDay__selected_span {
    color: var(--mdc-theme-primary);
    background: rgba(0, 171, 68, 0.1);

  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background:var(--mdc-theme-primary);
    color: #fff
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: var(--mdc-theme-primary);
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    color: #007a87
  }
  .CalendarDay__hovered_span:active {
    background: #80e8e0;
    color: #007a87
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    color: #82888a
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: var(--color-disabled);
    color: #cacccd
  }
  .CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
  }
  .CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
  }
  .CalendarMonth {
    background: var(--mdc-theme-background);
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  #month_left {
    position: absolute;
    top: 18px;
    left: 28px;
  }
  #month_right {
    position: absolute;
    top: 18px;
    right: 28px;
  }
  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate
  }
  .CalendarMonth_caption {
    color: var(--mdc-theme-text);
    font-family: "IBM Plex Sans";
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 44px;
    caption-side: initial
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
  }
  .CalendarMonthGrid {
    background: var(--mdc-theme-background);
    text-align: left;
    z-index: 0
  }
  .CalendarMonthGrid__animating {
    z-index: 1
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
  }
  .CalendarMonthGrid__vertical,
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2
  }
  .DayPickerNavigation__horizontal {
    height: 0
  }
  .DayPickerNavigation__verticalScrollable_prevNav {
    z-index: 1
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative
  }
  .DayPickerNavigation__bottom {
    height: auto
  }
  .DayPickerNavigation__bottomDefault {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
  }
  .DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
  }
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
  }
  .DayPickerNavigation_button__default:active {
    background: #f2f2f2
  }
  .DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
  }
  .DayPickerNavigation_button__disabled:focus,
  .DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
  }
  .DayPickerNavigation_button__disabled:active {
    background: 0 0
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
  }
  .DayPickerNavigation_bottomButton__horizontalDefault {
    position: static;
    margin: -10px 22px 30px
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault,
  .DayPickerNavigation_prevButton__verticalScrollableDefault {
    width: 100%
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
  }
  .DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
  }
  .DayPicker {
    background:  var(--mdc-theme-background) !important;;
    position: relative;
    text-align: left;
    top: -41px;
  }
  .DayPicker__horizontal {
    background: #fff
  }
  .DayPicker__verticalScrollable {
    height: 100%
  }
  .DayPicker__hidden {
    visibility: hidden
  }
  .DayPicker__withBorder {
    border-radius: 3px
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
  }
  .DayPicker_portal__vertical {
    position: initial
  }
  .DayPicker_focusRegion {
    outline: 0;
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
  }
  .DayPicker_weekHeaders {
    position: relative
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px
  }
  .DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
  }
  .DayPicker_weekHeader__vertical {
    left: 50%
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    max-height: 255px;
    border-radius: 3px;
    top: 10px !important;
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: height .2s ease-in-out;
    -moz-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
  }
  .DateInput {
    display: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
  }
  .DateInput__small {
    width: 97px
  }
  .DateInput__block {
    width: 100%
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
  }
  #dpStartDateInput{
    color: var(--mdc-theme-text);
    background-color: var(--mdc-theme-main);
    padding-left: 30px;
    position: absolute;
    left: -47px;
    top: -25px
  }
  #dpEndDateInput{
    color: var(--mdc-theme-text);
    background-color: var(--mdc-theme-main);
    padding-left: 30px;
    position: absolute;
    right:-128px;
    top: -25px;
  }
  .DateInput_input {
    height: 32px;
    width: 205px;
    font-weight: 200;
    font-size: 13px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    border: 1px solid #AEB3B7;
    border-radius: 4px;
  }
  .DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 5px
  }
  .DateInput_input__regular {
    font-weight: auto
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  .DateInput_input__focused {
    outline: 0;
    border: 1px solid  var(--mdc-theme-primary);
    box-sizing: border-box;
    border-radius: 4px;
    background: var(--mdc-theme-background);
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }
  .DateInput_fang {
    //display: none;
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
  }
  .DateInput_fangShape {
    fill: #fff;
  }
  .DateInput_fangStroke {
    fill: #fff;
    fill: transparent
  }
  .DateRangePickerInput {
   display: flex;
    width: 410px;
    height: 32px;
    align-items: center;
    justify-content: center;
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2
  }
  .DateRangePickerInput__withBorder {
    width: 410px;
    align-items: center;
    justify-content: center;
  }
  .DateRangePickerInput__rtl {
    direction: rtl
  }
  .DateRangePickerInput__block {
    display: block
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px
  }
  .DateRangePickerInput_arrow {
    display: none;
    vertical-align: middle;
    color: #484848
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .DateRangePickerInput_clearDates__small {
    padding: 6px
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 9px
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .DateRangePicker {
    width: 430px;
    position: absolute;
    bottom: 67px;
    right: 60px;
    display: inline-block
  }
  .DateRangePicker__block {
    display: block
  }
  .DateRangePicker_picker {
    z-index: 1;
    background-color: var(--mdc-theme-background);
    position: absolute;
  }
  .DateRangePicker_picker__rtl {
    direction: rtl
  }
  .DateRangePicker_picker__directionLeft {
    // overwrite inline styling
    left: 0 !important;
  }
  .DateRangePicker_picker__directionRight {
    right: 0
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
